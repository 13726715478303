import { Injectable, inject, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { TranslocoService } from "@ngneat/transloco";
import { filter, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class I18nService {
  private translocoService = inject(TranslocoService);
  locale = signal<string>("en");
  translationLoaded = toSignal(
    this.translocoService.events$.pipe(
      filter((e) => e.type === "translationLoadSuccess"),
      map(() => true)
    ),
    {
      initialValue: false
    }
  );
}
